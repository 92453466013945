import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Button, IconButton } from '@material-ui/core';
import GitHubIcon from '@material-ui/icons/GitHub';

import "./Homepage.css";

export default function Homepage() {
    
    return (
        <div className="home-page">
            <Container fluid> 
            <Row>
                <p>
                    Nothing to see here...
                </p>
            </Row>
            <Row className="github">
                <IconButton aria-label="github" target="_blank" href="https://github.com/Tianrens">
                    <GitHubIcon fontSize="large" />
                </IconButton>        
            </Row>
            </Container>
        </div> 
    )
}